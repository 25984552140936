import React, { useEffect, useMemo, useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import Axios from "../../AxiosWrapper";
import { getGameDisplayName, showError } from "../../utils";
type Session = {
  date: string;
  session_count: string;
  player_count: string;
};
type Stats = {
  game: string;
  game_data: Session[];
};
export const GameStatsGraph_AllGames = () => {
  const [stats, setStats] = useState<Stats[]>();
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const { data } = await Axios.get("/api/stats/all_games");
        setStats(data);
      } catch (error) {
        showError("Failed to get session stats", error);
      }
    };
    fetchSessions();
  }, []);

  const getStartAndEndDate = useMemo(() => {
    if (!stats) return { start: new Date(), end: new Date() };
    //iterate over all the games and find the earliest and latest date
    let startDate = new Date();
    let endDate = new Date();
    stats.forEach((game) => {
      const gameStart = new Date(game.game_data[0].date);
      const gameEnd = new Date(game.game_data[game.game_data.length - 1].date);
      if (gameStart < startDate) startDate = gameStart;
      if (gameEnd > endDate) endDate = gameEnd;
    });
    return { start: startDate, end: endDate };
  }, [stats]);
  const getFilledData = (gameId: string) => {
    const data: { x: Date; y: number }[] = [];
    if (!stats) return [];
    const sessions = stats.find((x) => x.game === gameId)?.game_data;
    if (!sessions) return [];

    const { start, end } = getStartAndEndDate;

    let currentDate = start;
    const endDate = end;
    const msInADay = 24 * 60 * 60 * 1000;
    // loop over all the edays between the start and end date, and fill in the gaps
    while (currentDate <= endDate) {
      const session = sessions.find(
        (session) => new Date(session.date).getTime() === currentDate.getTime()
      );
      data.push({
        x: new Date(currentDate),
        y: +(session?.player_count || 0),
      });

      currentDate = new Date(currentDate.getTime() + msInADay);
    }

    return data;
  };

  const getRollingAverage = (gameId: string, groupSize: number = 10) => {
    const data = getFilledData(gameId);
    const rollingAverage = [];

    // Adjust the loop to increment by the size of the group
    for (let i = 0; i < data.length; i += groupSize) {
      const start = i;
      const end = Math.min(i + groupSize, data.length); // Ensure we do not go out of bounds
      const sum = data.slice(start, end).reduce((acc, curr) => acc + curr.y, 0);
      const average = Math.round(sum / (end - start));

      rollingAverage.push({
        x: data[start].x, // Use the starting date of the group
        y: average,
      });
    }
    return rollingAverage;
  };
  const renderMonthTick = (x: number) => {
    const date = new Date(x);
    return (
      months[(date.getMonth() + 12) % 12].substring(0, 3) +
      " " +
      date.getFullYear().toString().slice(-2)
    );
  };
  const renderAreaCharts = useMemo(() => {
    return [
      "ZombieVR",
      "ArcheryVR",
      "TronVR",
      "PVPVR",
      "ScifiEscapeVR",
      "CookdUp",
      "EspionageExpress",
      "Robbers",
    ].map((game) => (
      <VictoryLine
        style={{
          data: { stroke: (colors as any)[game] || "pink" },
          parent: { border: "1px solid #ccc" },
        }}
        key={game}
        labelComponent={
          <VictoryTooltip
            cornerRadius={1}
            flyoutStyle={{
              stroke: "black",
              strokeWidth: 0.1,
              fill: "rgb(37 41 49)",
            }}
            style={{ fontSize: 12, fill: "#aaa", zIndex: 10 }}
          />
        }
        labels={({ datum }: any) => getGameDisplayName(game) + ` ${datum.y}`}
        data={getRollingAverage(game)}
      />
    ));
  }, [stats]);
  return (
    <div>
      <br />
      <br />
      <div style={{ background: "#ffffff0f", padding: 15 }}>
        <VictoryChart
          width={2000}
          height={1200}
          theme={VictoryTheme.material}
          containerComponent={<VictoryVoronoiContainer radius={200} />}
        >
          <VictoryAxis
            style={{
              grid: { stroke: "#718096" },
              tickLabels: { fontSize: 12 },
            }}
            tickCount={20}
            tickFormat={renderMonthTick}
          />

          <VictoryAxis
            style={{
              grid: { stroke: "#718096", strokeDasharray: "2 10" },
              tickLabels: { fontSize: 12 },
            }}
            dependentAxis
            orientation="left"
          />
          {renderAreaCharts}
          {/* <VictoryLegend
            itemsPerRow={41}
            // x={20}
            // y={0}
            orientation="horizontal"
            gutter={20}
            style={{
              border: { stroke: "black" },
              labels: { fontSize: 15 },
            }}
          /> */}
        </VictoryChart>
      </div>
    </div>
  );
};
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const colors = {
  ZombieVR: "red",
  ArcheryVR: "blue",
  TronVR: "green",
  PVPVR: "purple",
  ScifiEscapeVR: "orange",
  CookdUp: "yellow",
  EspionageExpress: "pink",
  Robbers: "brown",
};
