import { Button, Card, InputNumber, Select, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import Axios from "../../AxiosWrapper";
import { showError } from "../../utils";

type Props = {
  userId: number;
};
type AffiliateInfo = {
  email: string;
  id: number;
  purchased_credits: number;
};
type AffiliateSettings = {
  affiliate_payout_type: "cash" | "credits";
  affiliate_payout_rate: number;
};
export const Affiliate = (props: Props) => {
  const [affiliateInfo, setAffiliateInfo] = useState<AffiliateInfo[]>();
  const [affiliateSettings, setAffiliateSettings] =
    useState<AffiliateSettings>();
  const [users, setUsers] = useState<{ id: number; email: string }[]>();
  const [userToAdd, setUserToAdd] = useState<number>();
  useEffect(() => {
    const fetchAvailableUsers = async () => {
      try {
        const res = await Axios.get(`/api/admin/list_users`);
        setUsers(res.data);
      } catch (error) {
        showError("failed to get list of users", error);
      }
    };
    fetchAvailableUsers();
    fetchAffiliateUsers();
    fetchAffiliateSettings();
  }, []);
  const fetchAffiliateUsers = async () => {
    try {
      const res = await Axios.get(
        `/api/admin/users/${props.userId}/affiliate_info`
      );
      setAffiliateInfo(res.data);
    } catch (error) {
      showError("failed to get user affiliate info", error);
    }
  };
  const fetchAffiliateSettings = async () => {
    try {
      const res = await Axios.get(
        `/api/admin/users/${props.userId}/affiliate_settings`
      );
      setAffiliateSettings(res.data);
    } catch (error) {
      showError("failed to get user affiliate settings", error);
    }
  };

  const addAffiliateUser = async () => {
    try {
      await Axios.post(`/api/admin/users/${props.userId}/referred_user`, {
        referredUserId: userToAdd,
      });
      fetchAffiliateUsers();
    } catch (error) {
      showError("failed to add affiliate user", error);
    }
  };
  const changeAffiliateSettings = async (
    payoutRate: number | undefined,
    payoutType: "credits" | "cash" | undefined
  ) => {
    try {
      await Axios.post(`/api/admin/users/${props.userId}/affiliate_settings`, {
        affiliate_payout_rate:
          payoutRate || affiliateSettings?.affiliate_payout_rate || 20,
        affiliate_payout_type:
          payoutType || affiliateSettings?.affiliate_payout_type || "credits",
      });
      fetchAffiliateSettings();
    } catch (error) {
      showError("failed to update affiliate settings", error);
    }
  };
  if (!affiliateInfo || !users)
    return (
      <Card>
        <Spin />
      </Card>
    );
  return (
    <Card>
      add a referred user:
      <br />
      <Select
        onSelect={(value: any) => setUserToAdd(+value)}
        showSearch
        style={{ width: 500 }}
        filterOption={(input: any, option: any) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {users.map((user) => (
          <Select.Option key={user.id} value={user.id}>
            {user.email}
          </Select.Option>
        ))}
      </Select>
      <Button disabled={!userToAdd} onClick={addAffiliateUser}>
        Add
      </Button>
      <br />
      <br />
      affiliate payout rate:
      <InputNumber
        value={affiliateSettings?.affiliate_payout_rate}
        onChange={(value) => changeAffiliateSettings(+(value || 0), undefined)}
      />
      <br />
      <br />
      Affiliate payment type:
      <Select
        value={affiliateSettings?.affiliate_payout_type}
        onSelect={(value: any) =>
          changeAffiliateSettings(undefined, value as any)
        }
      >
        <Select.Option value="credits">credits</Select.Option>
        <Select.Option value="cash">cash</Select.Option>
      </Select>
      <br />
      <br />
      referred users:
      <Table dataSource={affiliateInfo} columns={columns} />
    </Card>
  );
};
const columns = [
  {
    title: "email",
    dataIndex: "email",
    key: "email",
  },

  {
    title: "user ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "€ spent",
    dataIndex: "total_spent",
    key: "total_spent",
    render: (x: string) => +x / 100,
  },
];
