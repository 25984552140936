import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { setToken, showError } from "../utils";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Axios from "../AxiosWrapper";

type FormValues = {};
export const Login = () => {
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: FormValues) => {
    setLoading(true);
    try {
      const res = await Axios.post("/api/login", values);
      const { token } = res.data;
      setToken(token);
      message.success("Successfully logged in!");
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } catch (error: any) {
      showError("Failed to login", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form
      onFinish={onFinish}
      className="login-form"
      style={{ maxWidth: "30em" }}
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Please enter your email address!" },
        ]}
      >
        <Input
          placeholder="Email"
          size="large"
          prefix={<UserOutlined type="user" />}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please enter your Password!" }]}
      >
        <Input
          size="large"
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};
