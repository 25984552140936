import { Slider, Spin } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";
import Axios from "../AxiosWrapper";
import { showError } from "../utils";
import "mapbox-gl/dist/mapbox-gl.css";
type UserAddress = {
  email: string;
  latitude: number;
  longitude: number;
  country_code: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  postal_code: string;
  state: string;
  latitude_override?: number;
  longitude_override?: number;
};
export const CustomerMap = () => {
  const [accessToken, setAccessToken] = useState("");
  const [addresses, setAddresses] = useState<UserAddress[]>([]);
  const [markerSize, setMarkerSize] = useState(1);
  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await Axios.get("/api/admin/mapbox_access_token");

        setAccessToken(token.data);
      } catch (error) {
        showError("Failed to get mapbox token", error);
      }
    };
    const getUsers = async () => {
      try {
        const _addresses = await Axios.get("/api/admin/user_addresses");
        setAddresses(_addresses.data);
      } catch (error) {
        showError("Failed to get users", error);
      }
    };
    getAccessToken();
    getUsers();
  }, []);
  const renderMarkers = () => {
    return addresses.map((address, index) => (
      <CustomMarker
        size={markerSize}
        key={index + "," + markerSize}
        address={address}
      />
    ));
  };
  if (!accessToken) return <Spin />;
  console.log("re-render", markerSize);
  return (
    <div>
      <Slider
        min={0}
        max={1}
        value={markerSize}
        onChange={(x) => setMarkerSize(x || 1)}
        step={0.01}
      />
      <Map
        mapboxAccessToken={accessToken}
        style={{ width: 1600, height: 1000 }}
        mapStyle="mapbox://styles/mapbox/dark-v10"
      >
        {renderMarkers()}
      </Map>
    </div>
  );
};

type MarkerProps = {
  address: UserAddress;
  size: number;
};
const CustomMarker = (props: MarkerProps) => {
  const [showInfo, setShowInfo] = useState(false);
  const {
    latitude,
    longitude,
    address_line_1,
    address_line_2,
    city,
    state,
    country_code,
    email,
    latitude_override,
    longitude_override,
  } = props.address;
  if ((!latitude && !latitude_override) || (!longitude && !longitude_override))
    return;
  return (
    <Marker
      scale={props.size}
      onClick={() => setShowInfo(!showInfo)}
      latitude={latitude_override || latitude}
      longitude={longitude_override || longitude}
    >
      {showInfo && (
        <div
          style={{ background: "#232323", position: "absolute", padding: 16 }}
        >
          {email} <br />
          {address_line_1} <br />
          {address_line_2} <br /> {city} {state} {country_code}
        </div>
      )}
    </Marker>
  );
};
