import Axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

type Day = {
  month: number;
  day: number;
  year: number;
  count: string;
  player_count: string;
};
type Props = {};

type State = {
  signupData: { x: number; y: number }[];
  totalPlayerCount: number;
  currentMonthlyPlayerCount: number;
  previousMonthlyPlayerCount: number;
  count: number;
  data: { x: number; y: number }[];
  currentMonthlySessions: number;
  monthlySessionDifference: number;
  monthlySessionDifferencePercentage: number;
};
export default class GamesPlayedChart extends Component<Props, State> {
  state = {
    currentMonthlyPlayerCount: 0,
    previousMonthlyPlayerCount: 0,
    totalPlayerCount: 0,
    data: [] as { x: number; y: number }[],
    signupData: [] as { x: number; y: number }[],
    count: 0,
    currentMonthlySessions: 0,
    monthlySessionDifference: 0,
    monthlySessionDifferencePercentage: 0,
  };
  componentDidMount() {
    this.fetchStats();
    this.fetchGamesPlayedCount();
  }
  fetchGamesPlayedCount = async () => {
    const res = await Axios.get("/api/admin/games_played_count");
    this.setState({
      count: (res.data as any)[0]?.count || 0,
      totalPlayerCount: (res.data as any)[0]?.player_count || 0,
    });
  };
  fetchStats = async () => {
    const gameStats = await Axios.get("/api/admin/get_games_played_stats");
    const newSignups = await Axios.get("/api/admin/new_signups");
    this.getSignupsByDay(newSignups.data);
    const data = gameStats.data.map((day: Day) => {
      const date = new Date(day.year, day.month - 1, day.day, 0, 0, 0, 0);
      return {
        x: date.getTime(),
        y: parseInt(day.player_count),
        sessionCount: parseInt(day.count),
      };
    });
    this.get30DaySessionCount(data);

    this.setState({
      data,
    });
  };

  getSignupsByDay(data: any) {
    const signupData = data.map((day: Day) => {
      const date = new Date(day.year, day.month - 1, day.day, 0, 0, 0, 0);
      return {
        x: date.getTime(),
        y: parseInt(day.count),
      };
    });

    this.setState({ signupData });
  }
  get30DaySessionCount = (
    data: { x: number; y: number; sessionCount: number }[]
  ) => {
    let currentMonthlySessions = 0;
    let previous30DayCount = 0;
    let currentMonthlyPlayerCount = 0;
    let previousMonthlyPlayerCount = 0;

    const currentDate = Date.now();
    const monthInMs = 2_592_000_000;
    data.forEach((day) => {
      if (day.x > currentDate - monthInMs) {
        currentMonthlySessions += day.sessionCount;

        currentMonthlyPlayerCount += day.y || 0;
      } else if (day.x > currentDate - monthInMs * 2) {
        previous30DayCount += day.sessionCount;
        previousMonthlyPlayerCount += day.y || 0;
      }
    });
    this.setState({
      currentMonthlySessions,
      monthlySessionDifference: currentMonthlySessions - previous30DayCount,
      monthlySessionDifferencePercentage:
        Math.round((currentMonthlySessions / previous30DayCount - 1) * 10000) /
        100,
      currentMonthlyPlayerCount,
      previousMonthlyPlayerCount,
    });
  };
  renderGamecount = () => {
    const { count, currentMonthlySessions, totalPlayerCount } = this.state;
    const countDiff =
      Math.round((currentMonthlySessions / count) * 10000) / 100;
    return (
      <div style={{ marginRight: 20 }}>
        <h2>Total</h2>
        <h3>
          Games Played: {count}
          <span style={{ color: "green" }}> +{countDiff}%</span>
        </h3>
        <h3>Players: {totalPlayerCount}</h3>
        <h3>Avg player count: {toFixed(totalPlayerCount / count)}</h3>
      </div>
    );
  };
  //TODO: show graph of new signups

  renderMonthlyDifference() {
    const {
      monthlySessionDifferencePercentage,
      currentMonthlyPlayerCount,
      currentMonthlySessions,
    } = this.state;
    const isPositive = monthlySessionDifferencePercentage > 0;

    return (
      <div>
        <h2>Last 30 days</h2>

        <h3>
          Games Played: {this.state.currentMonthlySessions}{" "}
          <span
            style={{
              color: monthlySessionDifferencePercentage > 0 ? "green" : "red",
            }}
          >
            {`${isPositive ? "+" : ""}${monthlySessionDifferencePercentage}`}%
          </span>
        </h3>

        <h3>Players: {currentMonthlyPlayerCount} </h3>
        <h3>
          Avg player count:{" "}
          {toFixed(currentMonthlyPlayerCount / currentMonthlySessions)}
        </h3>
      </div>
    );
  }
  renderMonthTick(x: number) {
    const date = new Date(x);
    return (
      months[(date.getMonth() + 12) % 12].substring(0, 3) +
      " " +
      date.getFullYear().toString().slice(-2)
    );
  }
  render() {
    if (!this.state.data.length) return null;
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {this.renderGamecount()}
          {this.renderMonthlyDifference()}
        </div>

        <VictoryChart
          width={1600}
          height={800}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryVoronoiContainer
              disable={false}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  flyoutStyle={{
                    stroke: "black",
                    strokeWidth: 0.1,
                    fill: "rgb(37 41 49)",
                  }}
                  style={{ fontSize: 12, fill: "#aaa", zIndex: 10 }}
                />
              }
              labels={({ datum }) =>
                `${new Date(datum.x).toDateString()}, ${datum.y}`
              }
              // allowZoom={true}
              // minimumZoom={{ x: 8.64e7 * 4, y: 1 }}
              // zoomDimension="x"
              // onZoomDomainChange={()=>{}}
            />
          }
        >
          <VictoryAxis
            style={{
              grid: { stroke: "#718096" },
              tickLabels: { fontSize: 12 },
            }}
            tickCount={20}
            tickFormat={this.renderMonthTick}
          />

          <VictoryAxis
            style={{
              grid: { stroke: "#718096", strokeDasharray: "2 10" },
              tickLabels: { fontSize: 12 },
            }}
            dependentAxis
            orientation="left"
          />

          <VictoryLine
            style={{
              data: { stroke: "#c43a31", strokeWidth: "2px" },
            }}
            data={this.state.data}
          />

          <VictoryLine
            //
            style={{
              data: { stroke: "#2773bf", strokeWidth: "2px" },
            }}
            data={getMovingAverage(this.state.data, 60, true)}
          />
          <VictoryLine
            interpolation="basis"
            style={{
              data: { stroke: "purple", strokeWidth: "2px" },
            }}
            data={getMovingAverage(this.state.signupData, 40, false)}
          />
        </VictoryChart>
      </div>
    );
  }
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function toFixed(x: number) {
  return Math.round(x * 100) / 100;
}

function getMovingAverage(
  data: {
    x: number;
    y: number;
  }[],

  duration: number,
  useAverage: boolean
) {
  const startDate = moment(data[data.length - 1].x);
  const endDate = moment();
  const numberOfDays = endDate.diff(startDate, "days");
  const days: { [x: number]: number } = {};

  data.forEach((day) => {
    days[day.x] = day.y;
  });
  for (let i = 0; i < numberOfDays; i++) {
    const currentDay = startDate.clone().add(i, "day").valueOf();

    days[currentDay] = days[currentDay] || 0;
  }

  const newData: {
    x: number;
    y: number;
  }[] = [];
  Object.keys(days).forEach((day) => {
    const x = parseInt(day);
    newData.push({ x, y: days[x] });
  });
  newData.sort((a: any, b: any) => a.x - b.x);
  const previousData: number[] = [];
  let currentIndex = 0;
  const movingAverageData = newData.map((day) => {
    previousData[currentIndex % duration] = day.y;
    currentIndex++;
    let y = previousData.reduce((a, b) => a + b, 0);
    if (useAverage) y /= previousData.length;
    return { x: day.x, y: Math.round(y * 10) / 10 };
  });
  return movingAverageData;
}
