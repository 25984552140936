import React from "react";

import CreateUser from "../Components/CreateUser";
import { UserList } from "../Components/UserList";
export const Admin = () => {
  return (
    <div>
      <UserList />

      <br />
      <CreateUser />
      <br />
    </div>
  );
};
