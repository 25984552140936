import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getTokenData } from "./utils";

const PrivateRoute = ({ children }: any) => {
  // Add your own authentication on the below line.
  const isLoggedIn = getTokenData();
  const location = useLocation();

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after logging in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
