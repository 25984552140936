import { Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import Axios from "../AxiosWrapper";

type Props = {
  userId: number;
};
export const UserSettingsList = (props: Props) => {
  const [data, setData] = useState<any>();
  useEffect(() => {
    const getSettings = async () => {
      const res = await Axios.get(`/api/admin/users/${props.userId}/settings`);
      console.log(res);
      setData(res.data);
    };
    getSettings();
  }, []);
  const columns = [
    {
      title: "setting",
      dataIndex: "key",
      key: "key",
    },

    {
      title: "value",
      dataIndex: "value",
      key: "value",
      width: 400,
    },
  ];
  return (
    <Card>
      <h2>User Settings</h2>
      <Table
        style={{ height: 1000, overflow: "auto" }}
        dataSource={data}
        columns={columns}
      />
    </Card>
  );
};
