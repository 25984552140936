import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Axios from "../AxiosWrapper";
import { User } from "../types";
import { showError } from "../utils";
type Props = {
  currentUser: User | undefined;
  onClose: Function;
};
class ChangePasswordDialog extends Component<Props> {
  onFinish = async (values: any) => {
    try {
      await Axios.post("/api/admin/change_password", {
        email: this.props.currentUser?.email,
        password: values.password,
      });
      message.success("Successfully changed password!");
      this.props.onClose();
    } catch (error) {
      showError("Failed to change password", error);
    }
  };

  render() {
    return (
      <Form onFinish={this.onFinish} className="login-form">
        <br />
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Please enter a valid password!" },
            {
              min: 6,
              required: true,
              message: "Password needs to be at least 6 characters.",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="New Password"
          />
        </Form.Item>

        <Form.Item
          name="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The two passwords don't match!");
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Confirm Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Confirm new password
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default ChangePasswordDialog;
