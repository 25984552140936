import React, { useEffect, useState } from "react";
import Axios from "../../AxiosWrapper";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

export const WeeklyActiveUsersChart = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const getActiveUsers = async () => {
      try {
        const response = await Axios.get("/api/admin/weekly_active_users");
        const formattedData = response.data.map((datum: any) => ({
          x: new Date(datum.week),
          y: parseInt(datum.active_user_count),
        }));
        setData(formattedData.slice(0, -1)); //skip the last element because it's the current week
      } catch (error) {
        console.error("Error fetching active user data:", error);
      }
    };
    getActiveUsers();
  }, []);
  if (!data || !data.length) return null;
  return (
    <div>
      <h2>Weekly Active Users</h2>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={20}
        width={1600}
        height={800}
        containerComponent={
          <VictoryVoronoiContainer
            disable={false}
            labelComponent={
              <VictoryTooltip
                cornerRadius={1}
                flyoutStyle={{
                  stroke: "black",
                  strokeWidth: 0.1,
                  fill: "rgb(37 41 49)",
                }}
                style={{ fontSize: 12, fill: "#aaa", zIndex: 10 }}
              />
            }
            labels={({ datum }) =>
              `${new Date(datum.x).toDateString()}, ${datum.y}`
            }
          />
        }
      >
        <VictoryAxis
          label="Week"
          style={{
            grid: { stroke: "#718096", strokeDasharray: "2 10" },
            tickLabels: { fontSize: 12 },
          }}
          tickFormat={renderMonthTick}
          tickCount={10}
        />
        <VictoryAxis
          dependentAxis
          label="Active Users"
          style={{
            grid: { stroke: "#718096", strokeDasharray: "2 10" },
            tickLabels: { fontSize: 12 },
          }}
          tickCount={10}
        />
        <VictoryLine
          interpolation={"linear"}
          style={{
            data: { stroke: "#c43a31", strokeWidth: "2px" },
          }}
          data={data}
        />
      </VictoryChart>
    </div>
  );
};
function renderMonthTick(x: number) {
  const date = new Date(x);
  return (
    months[(date.getMonth() + 12) % 12].substring(0, 3) +
    " " +
    date.getFullYear().toString().slice(-2)
  );
}
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
