import { useState, useEffect } from "react";
import Axios from "../AxiosWrapper";
import { showError } from "../utils";

export const useFetchData = (url: string) => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res: any = await Axios.get(url);
        setData(res.data);
      } catch (err) {
        setError(`Failed to fetch data from ${url}`);
        showError(`Failed to fetch data from ${url}`, err);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [url]);

  return { data, isLoading, error };
};
