import { Card, Table } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import Axios from "../AxiosWrapper";
import { months, showError } from "../utils";

type UserAffiliateData = {
  email: string;
  affiliate_payout_rate: number;
  affiliate_payout_type: "cash" | "credits";
  month_data: [{ total_spent: number; month: number; year: number }];
};
export const AffiliateOverview = () => {
  const [affiliateData, setAffiliateData] = useState<UserAffiliateData[]>();
  useEffect(() => {
    const getAffiliateData = async () => {
      try {
        const res = await Axios.get("/api/admin/affiliate_overview");
        setAffiliateData(res.data);
      } catch (error) {
        showError("Failed to get affiliate data", error);
      }
    };

    getAffiliateData();
  }, []);

  const columns = [
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "data",
      key: "data",
      // eslint-disable-next-line react/display-name
      render: (user: UserAffiliateData) => {
        const monthDataColumns = [
          {
            title: "year",
            dataIndex: "year",
            key: "year",
          },
          {
            title: "month",
            dataIndex: "month",
            key: "month",
            render: (month: number) => months[month - 1],
          },

          {
            title: "€ spent",
            dataIndex: "total_spent",
            key: "total_spent",
            render: (x: string) => +x / 100,
          },
          {
            title: "payout rate",
            key: "affiliate_payout_rate",
            render: () => user.affiliate_payout_rate + "%",
          },
          {
            title: "Expected payout",
            key: "payout",
            render: (x: any) => {
              const value =
                Math.round(
                  (((+x.total_spent / 100) * user.affiliate_payout_rate) /
                    100) *
                    10
                ) / 10;
              const prefix = user.affiliate_payout_type === "cash" ? "€" : "";
              const suffix =
                user.affiliate_payout_type === "cash" ? "" : " credits";

              return prefix + value + suffix;
            },
          },
        ];
        return (
          <Card>
            <Table columns={monthDataColumns} dataSource={user.month_data} />
          </Card>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={affiliateData}
      style={{ width: 1200 }}
    />
  );
};
//
