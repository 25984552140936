import React, { Component } from "react";
import Axios from "../AxiosWrapper";
import { Table, Spin, Card } from "antd";
import { User } from "../types";
import { getGameDisplayName } from "../utils";
const { Column } = Table;

type GameData = {
  zombie_count: number;
  tron_count: number;
  archery_count: number;
  gungame_count: number;
  game_count: number;
  total_player_count: number;
  average_player_count: number;
  month: number;
  year: number;
  key: string;
};
type Props = {
  user: User;
};
type State = {
  gameData: GameData[] | undefined;
};
export default class GameDataView extends Component<Props, State> {
  state = { gameData: [] };
  componentDidMount() {
    this.getGameData();
  }

  getGameData = async () => {
    const data = (
      await Axios.get("/api/admin/get_user_game_data", {
        userId: this.props.user.id,
      })
    ).data;

    const gameData = data.map((monthData: GameData) => {
      return { ...monthData, key: monthData.month + "-" + monthData.year };
    });
    this.setState({ gameData });
  };
  render() {
    if (!this.state.gameData) return <Spin />;

    return (
      <Card>
        <h2>Games Played</h2>
        <Table
          dataSource={this.state.gameData}
          expandable={{
            expandedRowRender: (x: any) => (
              <ul>
                {x.games.map(({ game, player_count }: any) => (
                  <li>
                    {getGameDisplayName(game)}: {player_count} players
                  </li>
                ))}
              </ul>
            ),
          }}
        >
          <Column
            title="month"
            dataIndex="month"
            key="month"
            render={(index: number) => months[index - 1].slice(0, 3)}
          />
          <Column
            title="year"
            dataIndex="year"
            key="year"
            render={(text: string) => text}
          />
          <Column
            title="players"
            dataIndex="total_player_count"
            key="total_player_count"
            render={(text: string) => text}
          />
          <Column
            title="games"
            dataIndex="game_count"
            key="game_count"
            render={(text: string) => text}
          />
        </Table>
      </Card>
    );
  }
}
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
