import React, { useEffect, useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import Axios from "../AxiosWrapper";
import { getGameDisplayName, showError } from "../utils";
import { useParams } from "react-router-dom";
type Session = {
  date: string;
  session_count: string;
  player_count: string;
};
type Stats = {
  sessions: Session[];
  totals: {
    game_count: string;
    player_count: string;
  };
};
export const GameStatsGraph = () => {
  const [stats, setStats] = useState<Stats>();
  const { gameId } = useParams<{ gameId: string }>();
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const { data } = await Axios.get("/api/stats/game/" + gameId);
        console.log(data);
        setStats(data);
      } catch (error) {
        showError("Failed to get session stats", error);
      }
    };
    fetchSessions();
  }, []);
  const getFilledData = () => {
    const data: { x: Date; y: number }[] = [];
    if (!stats || !stats.sessions) return [];
    const { sessions } = stats;
    let currentDate = new Date(sessions[0].date);
    const endDate = new Date(sessions[sessions.length - 1].date);
    const msInADay = 24 * 60 * 60 * 1000;
    // loop over all the edays between the start and end date, and fill in the gaps
    while (currentDate <= endDate) {
      const session = sessions.find(
        (session) => new Date(session.date).getTime() === currentDate.getTime()
      );
      data.push({
        x: new Date(currentDate),
        y: +(session?.player_count || 0),
      });

      currentDate = new Date(currentDate.getTime() + msInADay);
    }

    return data;
  };

  // const data = stats?.sessions.map((session) => ({
  //   x: new Date(session.date),
  //   y: +session.player_count,
  // }));
  const getRollingAverage = () => {
    const data = getFilledData();
    const rollingAverage = [];
    for (let i = 0; i < data.length; i++) {
      const start = i - 6 >= 0 ? i - 6 : 0;
      const end = i + 1;
      const sum = data.slice(start, end).reduce((acc, curr) => acc + curr.y, 0);
      rollingAverage.push({
        x: data[i].x,
        y: Math.round(sum / Math.min(7, end - start)),
      });
    }
    return rollingAverage;
  };
  const renderMonthTick = (x: number) => {
    const date = new Date(x);
    return (
      months[(date.getMonth() + 12) % 12].substring(0, 3) +
      " " +
      date.getFullYear().toString().slice(-2)
    );
  };

  return (
    <div>
      <h1>{getGameDisplayName(gameId)} Stats</h1>
      <div
      // style={{ background: "#ffffff0f", width: "fit-content", padding: 15 }}
      >
        Total Games: {stats?.totals.game_count}
        <br />
        Total Players: {stats?.totals.player_count}
      </div>

      <br />
      <br />
      <div style={{ background: "#ffffff0f", padding: 15 }}>
        <h2>Players</h2>
        <VictoryChart
          width={1600}
          height={800}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryVoronoiContainer
              disable={false}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  flyoutStyle={{
                    stroke: "black",
                    strokeWidth: 0.1,
                    fill: "rgb(37 41 49)",
                  }}
                  style={{ fontSize: 12, fill: "#aaa", zIndex: 10 }}
                />
              }
              labels={({ datum }) =>
                `${new Date(datum.x).toDateString()}, ${datum.y} players`
              }
              // allowZoom={true}
              // minimumZoom={{ x: 8.64e7 * 4, y: 1 }}
              // zoomDimension="x"
              // onZoomDomainChange={()=>{}}
            />
          }
        >
          <VictoryAxis
            style={{
              grid: { stroke: "#718096" },
              tickLabels: { fontSize: 12 },
            }}
            tickCount={20}
            tickFormat={renderMonthTick}
          />

          <VictoryAxis
            style={{
              grid: { stroke: "#718096", strokeDasharray: "2 10" },
              tickLabels: { fontSize: 12 },
            }}
            dependentAxis
            orientation="left"
          />
          <VictoryLine
            style={{
              data: { stroke: "#c43a31", strokeWidth: "2px" },
            }}
            data={getFilledData()}
          />
          <VictoryLine
            style={{
              data: { stroke: "#008fff", strokeWidth: "2px" },
            }}
            data={getRollingAverage()}
          />
        </VictoryChart>
      </div>
    </div>
  );
};
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
