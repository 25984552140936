import axios from "axios";
import { getToken } from "./utils";
axios.defaults.headers.common["x-access-token"] = getToken();
export default class Axios {
  static get(url: string, params?: {}, options?: {}) {
    return axios.get(url, {
      ...options,
      params,
    });
  }

  static post(url: string, data: {}, options?: {}) {
    return axios.post(url, data);
  }
  static put(url: string, data: {}, options?: {}) {
    return axios.put(url, data);
  }
  static delete(url: string, data: {}, options?: {}) {
    return axios.delete(url, data);
  }
}
