import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, theme } from "antd";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm,
      token: {
        colorText: "#fff",
        colorBgBase: "#22262a",
      },
      components: {
        Layout: {
          colorBgHeader: "#1e2c3d",
        },
        Menu: {
          //  colorBgContainer: headerColor,
        },
        Table: {
          colorBgContainer: "rgba(100,100,100,0.1)",
        },
        Card: {
          colorBgContainer: "rgba(100,100,100,0.1)",
        },
      },
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ConfigProvider>
);
