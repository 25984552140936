import { Modal } from "antd";
import axios from "axios";
import jwt from "jsonwebtoken";
export function setToken(token: string) {
  localStorage.setItem("jwt", token);
}
export function getToken() {
  return localStorage.getItem("jwt");
}
export function getTokenData() {
  const token = getToken();
  if (!token) return false;
  const decodedToken = jwt.decode(token) as { [key: string]: any };
  if (decodedToken) {
    const expiry: number = decodedToken.exp * 1000;
    const hoursRemaining =
      Math.round(((expiry - Date.now()) / (1000 * 60 * 60)) * 10) / 10;
    return {
      isValid: hoursRemaining > 0,
      hoursRemaining,
      ...decodedToken,
    };
  }
  return false;
}

export function isLoggedIn() {
  const data = getTokenData();
  if (!data) return false;
  return true;
}
export function showError(message: string, error: any) {
  if (axios.isAxiosError(error)) {
    Modal.error({
      title: message,
      content: error.response?.data?.message || error.response?.data,
    });
  } else {
    Modal.error({
      title: message,
      content: error.message.toString(),
    });
  }
}
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function getGameDisplayName(game: string | undefined) {
  if (!game) return undefined;
  const games: any = {
    ArcheryVR: "Arrowsong",
    ScifiEscapeVR: "Wayfinders",
    ZombieVR: "Dead Ahead",
    CookdUp: "Cook'd Up",
    EspionageExpress: "EspionageExpress",
    Robbers: "Cops Vs Robbers",
    PVPVR: "Quantum Arena",
    TronVR: "Cyber Shock",
  };

  return games[game] || game;
}
