import { Button, Form, Input, List, message, Spin, Typography } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import Axios from "../../AxiosWrapper";
import { showError } from "../../utils";
const { Title } = Typography;
type Props = {
  userId: number;
};
type Platform = {
  id: number;
  user_id: number;
  created_on: number;
  router_password: string;
  vive_email: string;
  vive_password: string;
};
export const Turnkey = (props: Props) => {
  const { userId } = props;
  const [loading, setLoading] = useState(true);

  const [platform, setPlatform] = useState<Platform>();
  useEffect(() => {
    const fetchTurnkeyPlatform = async () => {
      try {
        const { data: newPlatform } = await Axios.get(
          `/api/admin/users/${userId}/turnkey`
        );
        setPlatform(newPlatform);
        setLoading(false);
      } catch (error) {
        showError("failed to get user turnkey platform", error);
      }
    };
    fetchTurnkeyPlatform();
  }, []);
  if (loading) return <Spin />;
  if (!platform) return <CreateTurnkey userId={userId} />;
  return (
    <div>
      <Title level={3}>Turnkey Platform</Title>
      <List>
        <List.Item>
          <b>Added on:</b> {new Date(platform.created_on).toLocaleString()}
        </List.Item>
        <List.Item>
          <b>Router password:</b> {platform.router_password}
        </List.Item>
        <List.Item>
          <b>Vive email:</b> {platform.vive_email}
        </List.Item>
        <List.Item>
          <b>Vive password:</b> {platform.vive_password}
        </List.Item>
      </List>
    </div>
  );
};

const CreateTurnkey = (props: Props) => {
  const { userId } = props;
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: any) => {
    console.log("create turnkey: ", values);
    setLoading(true);
    try {
      await Axios.post(`/api/admin/users/${userId}/turnkey`, values);
      message.success("created turnkey platform");
      window.location.reload();
    } catch (error) {
      showError("failed to create turnkey platform", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form onFinish={onFinish}>
      <Form.Item label="Router password" name="router_password">
        <Input />
      </Form.Item>
      <Form.Item label="Vive email" name="vive_email">
        <Input />
      </Form.Item>
      <Form.Item label="Vive password" name="vive_password">
        <Input />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Add Turnkey Platform
      </Button>
    </Form>
  );
};
