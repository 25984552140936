import { Button, Card, Table, Typography } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import Axios from "../../AxiosWrapper";
import { showError } from "../../utils";
const { Title } = Typography;
type OrderInfo = {
  order_id: number;
  invoice_number: number;
  amount_charged: number;
  date_paid: string;
};
type Props = {
  userId: number;
};
export const UserOrders = (props: Props) => {
  const [orders, setOrders] = useState<OrderInfo[]>([]);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res: any = await Axios.get(
          `/api/admin/users/${props.userId}/orders`
        );
        setOrders(res.data);
      } catch (error) {
        showError("Failed to get user orders", error);
      }
    };

    fetchOrders();
  }, []);

  const columns = [
    {
      title: "Date",
      key: "timestamp",
      render: (x: OrderInfo) => new Date(x.date_paid).toLocaleDateString(),
    },
    // {
    //   title: "No Credits",
    //   dataIndex: "credit_delta",
    //   key: "credit_delta",
    //   //   render: (x: OrderInfo) => JSON.parse(x.products),
    // },
    {
      title: "price_paid",
      key: "price_paid",
      render: (x: OrderInfo) => "€" + x.amount_charged / 100, //is stored as an integer so 1000 = 10.00 euros
    },
    {
      title: "",
      render: (x: OrderInfo) => downloadInvoiceButton(x),
    },
  ];
  const downloadInvoiceButton = (orderInfo: OrderInfo) => {
    return (
      <Button onClick={() => downloadInvoice(orderInfo)}>
        Download Invoice
      </Button>
    );
  };

  return (
    <Card>
      <Title level={3}>User Orders</Title>
      <Table dataSource={orders} columns={columns} />
    </Card>
  );
};

async function downloadInvoice(orderInfo: OrderInfo) {
  const { order_id, invoice_number } = orderInfo;
  try {
    const invoice = await Axios.get(
      `/api/admin/orders/${order_id}/invoice`,
      {},
      { responseType: "arraybuffer" }
    );
    const file = new Blob([invoice.data], { type: "application/pdf" });

    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `invoice_order${invoice_number}.pdf`;
    link.click();
  } catch (error) {
    showError("Failed to download order invoice", error);
  }
}
